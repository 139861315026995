<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          

          <b-col cols="12" md="10">
                <b-row>
                  <b-col
                cols="12"
                md="6"
                class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_margin_bottom_1rem ">
                
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sites"
                  label="site_name"
                  class="w-100"
                  placeholder="Project Site"
                  v-model="siteData"
                  :clearable="false"
                  @input="resetData();"
                >
                  <!-- @input="filterTable" -->
                <template #selected-option="{ site_name }">
                  <span v-if="site_name.length < 23">{{site_name}}</span>
                  <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                </template>
              </v-select>
              </b-col>

              <b-col cols="12" md="6" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                <v-select
                v-model="locations"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="allLocations"
                placeholder="Select Locations"
                :close-on-select=false
                 :clear-on-select=false
                 class="multiselect_muliple_options"
                />
              </b-col>

              <b-col cols="12" md="6" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                <div class="d-flex align-items-center">
                      
                      <flat-pickr
                        v-model="date_range"
                        :config="date_config"
                        class="form-control flat-picker"
                        placeholder="Select Date Range"
                        @on-change="setDate()"
                      />

                    </div>
              </b-col>

              <b-col cols="12" md="6" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                <v-select
                v-model="report_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Pass', 'Fail']"
                placeholder="Select Report Status"
                />
              </b-col>

            </b-row>
          </b-col>
          
          <b-col cols="12" md="2">
            <b-button variant="danger" class="mobile-margin-bottom" @click="resetFilter()">
              <span class="text-nowrap">Reset</span>
            </b-button>

            <!-- <b-button variant="dark" class="mobile-margin-bottom ml-1" @click="openModal()">
              <span class="text-nowrap">Validator</span>
            </b-button> -->
          </b-col>
          
          <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_margin_bottom_1rem ">
            <b-form-datepicker v-model="start" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="updateStart()"/>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_margin_bottom_1rem ">
            <b-form-datepicker  v-model="end" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en"  :min="disabledDates()"/>
          </b-col> -->

          <!-- <b-col 
           cols="12"
           md="3"
           class="mb-md-0 mobile_tab_max_width_flex"
          >

            
          </b-col> -->
          
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0" v-if="siteData != null">

      <!-- tab component starts -->

      <b-tabs pills justified class="margin_top_zero_mobile tab_ul button-padding table_button_tab">                
            
            <b-tab title="Draft" lazy @click="tab_status = 'draft'" :active="tab_status == 'draft'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Draft</span>
                  <b-badge variant="secondary" style="margin-left: 5px;" v-if="draft > 0">
                      <span>{{ draft }}</span>
                  </b-badge>
                </div>
              </template>
              
            </b-tab>
            
            <b-tab title="Validate" lazy @click="tab_status = 'validate'" :active="tab_status == 'validate'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Validate</span>
                  <b-badge variant="warning" style="margin-left: 5px;" v-if="validate > 0 ">
                      <span>{{validate}}</span>
                  </b-badge>
                </div>
              </template>
              
            </b-tab>
            
            <b-tab title="Completed" lazy @click="tab_status = 'completed'" :active="tab_status == 'completed'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Completed</span>
                  <b-badge variant="warning" style="margin-left: 5px;" v-if="completed > 0 ">
                      <span>{{completed}}</span>
                  </b-badge>
                </div>
              </template>
              
            </b-tab>

            

            <b-tab title="All" lazy @click="tab_status = 'all'" :active="tab_status == 'all'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>All</span>
                  <b-badge variant="warning" style="margin-left: 5px;" v-if="all > 0">
                      <span>{{ all }}</span>
                  </b-badge>
                </div>
              </template>
              
            </b-tab>

            <ReportComponent :siteData="siteData" :locations="locations" :start="start" :end="end" :report_status="report_status" :tab_status="tab_status" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus" :key="tab_status"/>

        </b-tabs>

      <!-- tab component ends -->

      
    </b-card>

    <b-modal 
        id="qi-modal" centered ref="qi-modal" cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        title="Validator Setting"
        @show="getQMSettings"
        @hidden="getQMSettings"
        @ok="formSubmitQISetting"
        no-close-on-backdrop
        size="mb"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body"> {{error_message}} </div>
        </b-alert>

        <b-form>
        
        <b-row>
          
          <b-col md="12">      
            <b-form-group label="Validator" class="required" v-if="itemsQI.length > 0">
              <b-form-select v-model="itemsQI[0].validateLevelOne">
                <b-form-select-option value='' disabled>Select</b-form-select-option>
                <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <!-- <b-table 
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative reverse-padding"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumnsQI"
                :items = "itemsQI"
            >

                <template #cell(validateLevelOne)="itemsQI">
                  <b-form-select v-model="itemsQI.item.validateLevelOne">
                    <b-form-select-option value='' disabled>Select</b-form-select-option>
                    <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                  </b-form-select>
                </template>

            </b-table> -->


          </b-col>
        </b-row>

      </b-form>

    </b-modal>
    
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BAlert,
  BPagination,VBTooltip,BCardBody,BFormCheckbox,BFormDatepicker,BBreadcrumb, BTabs, BTab, BFormSelect,BFormSelectOption, BForm , BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import ReportComponent from './ReportComponent.vue';
import Bus from "../../../event-bus";
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BBreadcrumb,
    BTabs, BTab,
    ReportComponent,
    flatPickr, BForm, BAlert,
    BFormSelect,BFormSelectOption , BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
      moment: function(date) {
          return moment(date).format('DD MMM YYYY')
      },
    },
  data() {
    return {
      report_status: null,
      tab_status:'draft',
      assign_status:'all',
      items:[],
      isSortDirDesc: true,
      perPageOptions: [10, 20, 50, 100],
      perPage : 10,
      sortBy : 'order',
      webUrl: process.env.VUE_APP_SERVER_URL,
      totalRecords : 0,
      currentPage : 1,
      
      sites:[],
      siteData:null,

      locations: [],
			allLocations: [],
      
      // calendar vars 
      start:/* moment().tz('Asia/Singapore').toDate() */null,
      end:/* moment().tz('Asia/Singapore').toDate() */null,

      draft: 0,
      validate:0,
      completed:0,
      all:0,

      //  flat picker config
      date_config:{ 
        mode: 'range',
        dateFormat: 'd M Y',
        defaultDate: ['', '']
      },
      date_range:'' + ' to ' + '',

      // qm validation settings
      error_message:null,
      showDismissibleAlert:false,
      
      itemsQI :[],
      roles:[],

      tableColumnsQI: [
          { key: 'form', label: 'Form Name', sortable: false , thStyle:  {width: '50%'}},
          { key: 'validateLevelOne', label: 'Validate (Level 1)', sortable: false , thStyle:  {width: '50%'}}
      ],
    }
  },
  methods : {
    updateStatus(value){
			this.tab_status = value;
		},

    resetFilter(){
      this.locations = [];
			this.allLocations = [];

      this.allSites().then(()=>{
        this.allLocationsList();
      }) 

      this.start=null; 
      this.end = null; 
      this.date_range ='' + ' to ' + ''; 
      this.report_status= null
    },
    setDate(){
        var date = this.date_range.split(' to ');

        if (date[1]) {
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
        }else{
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
        }
		},

    updateCount(value){
			this.draft 			= value.draft;
			this.validate 	= value.validate;
			this.completed 	= value.completed;
			this.all 		    = value.all;
		},

    updateStatus(value){
			this.tab_status = value;
		},

    
    /*statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Project Site Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Project Site Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-site-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },*/

    
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              // if (this.sites.length == 1 ) {
              //     this.siteData = this.sites[0];

              //     this.filterTable();
              // }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);
              
              this.siteData = this.defaultSite(this.sites);

              this.allLocationsList().then(()=>{
                if (this.$route.params.location && this.$route.params.location != 'null') {
                  var t_location = [];

                  this.$route.params.location.split(',').forEach((t1) => {
                    this.allLocations.forEach((li) => {
                      if(li._id == t1){
                        t_location.push(li)
                      }
                    })
                  })
                  this.locations = t_location;
                }
              });
              
              return this.sites;
          }
      });
    },

    resetData(){
			this.locations = [];
			this.allLocations = [];
			
			this.allLocationsList();
		},

    allLocationsList(){
			return this.$store.dispatch(POST_API, {
				data:{
          site:this.siteData ? this.siteData._id : null,
          type:'QI'
				},
				api: '/api/all-locations'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					
					this.allLocations = data;

					if (this.$route.params.locations && this.$route.params.locations != 'null') {
			
						var locations = this.$route.params.locations.split(',');

						var selectedLocation = [];
						
						this.allLocations.forEach(function(item){
							
							if (locations.indexOf(item._id) >= 0) {
								selectedLocation.push(item);
							}
						})
						this.locations = selectedLocation;
					}
					return this.allLocations;
				}
			});
		},    
    
    // calendar functions
    updateStart(){
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      // this.filterTable();
    },

    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        return moment().format('YYYY-MM-DD');
      }
    },

    breadCrumb(){
      var item = [
      {
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Quality Management'
      },{
        to:null,
        text: 'Overview'
      },{
        to:null,
        text: 'Quality Inspection'
      }];
      return item;
    },


    // QI Setting functions below
    formSubmitQISetting(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.itemsQI
           },
           api: '/api/update-quality-management-settings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                this.successAlert().then(() => {
                    this.$refs['qi-modal'].hide();
                });
                
            }
        });
      
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
           data:{
             //role:this.$store.getters.currentUser.role
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.roles = this.$store.getters.getResults.data;
                
                return this.roles;
            }
        }); 
    },
    getQMSettings(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser.organization,
             role:this.$store.getters.currentUser.role,
           },
          //  api: '/api/client-custom-forms'
           api: '/api/get-quality-management-settings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.itemsQI = this.$store.getters.getResults.data;

                return this.itemsQI;
            }
        });
    },

    openModal(){
        this.showDismissibleAlert = false;
        this.$refs['qi-modal'].show();
    },
    
    
  },
  mounted() {
    this.allSites();
    
    // this.getQMSettings();
    this.getRoles();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
